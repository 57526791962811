/* eslint-disable camelcase */
import { useContext, useEffect } from 'react';
import { getExperimentVariant } from '@buzzfeed/react-components/lib/utils/abeagle';
import { ABeagleContext } from '../../contexts/index';
import {useTrackingContext} from '../../hooks';

/**
 * Serves the purpose of reading the ABeagle Feature Flags
 * and initializing the time spent metric handlers.
 */
export default function TimeSpentTrackingConsumer() {
  const { experiments } = useContext(ABeagleContext);
  const { trackTimeSpent } = useTrackingContext();


  useEffect(() => {
    let time_spent_handler = () => {};
    if (!experiments.stale && experiments.loaded) {
      const variant = getExperimentVariant(experiments, 'TimeSpentRO_1',
          { rejectErrors: false });
      if (variant === 'on') {
        time_spent_handler =  trackTimeSpent({});
      }
    }
    return time_spent_handler;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments.loaded]);

  return null;
}
