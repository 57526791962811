export const ChevronRight = ({ className = '' }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M5.5 3.5L10.5 8.5L5.5 13.5" stroke="#5246F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
