import { FacebookTracker } from '@buzzfeed/third-party-analytics';
import {CLUSTER, facebook_tracking_id} from "../../../constants";

let trackingInstance;

const fb = () => {
  if (!trackingInstance) {
    trackingInstance = new FacebookTracker({
      trackingId: facebook_tracking_id,
      cluster: CLUSTER,
    });
  }
  return trackingInstance;
};

export function trackFacebookPageView(data) {
  const tracker = fb();
  tracker.trackEvent({ eventType: 'PageView', data });
}
