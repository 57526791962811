export { default as ads } from '@buzzfeed/adlib/dist/module/core/ads';

// Components
import AdAwareness from '@buzzfeed/adlib/dist/module/components/awareness';
import AdBigstory from '@buzzfeed/adlib/dist/module/components/bigstory';
import { AdEx } from '@buzzfeed/adlib/dist/module/components/ex';
import AdFeedStory from '@buzzfeed/adlib/dist/module/components/feed-story';
import AdToolbar from '@buzzfeed/adlib/dist/module/components/toolbar';

// Templates
import AdAwarenessTemplate from '@buzzfeed/adlib/dist/module/components/awareness/index.svelte';
import AdBigstoryTemplate from '@buzzfeed/adlib/dist/module/components/bigstory/index.svelte';
import AdExTemplate from '@buzzfeed/adlib/dist/module/components/ex/index.svelte';
import AdFeedStoryTemplate from '@buzzfeed/adlib/dist/module/components/feed-story/index.svelte';
import AdToolbarTemplate from '@buzzfeed/adlib/dist/module/components/toolbar/index.svelte';

export const components = {
  AdAwareness,
  AdBigstory,
  AdEx,
  AdFeedStory,
  AdToolbar,
};

export const templates = {
  AdAwareness: AdAwarenessTemplate,
  AdBigstory: AdBigstoryTemplate,
  AdEx: AdExTemplate,
  AdFeedStory: AdFeedStoryTemplate,
  AdToolbar: AdToolbarTemplate,
};
