import React, { useContext, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ABeagleContext, PageContext } from '../../contexts';
import EXPERIMENTS from '../../constants/ab-tests';
import { useABeagleExperiments } from '@buzzfeed/react-components';
import { abeagleHost, CLUSTER } from '../../constants';
import { useUserId } from '@buzzfeed/react-components/lib/hooks/useUserId';
import {
  isOn,
  getExperimentVariant,
} from '@buzzfeed/react-components/lib/utils/abeagle';
import { useTrackingContext } from '../../hooks/tracking/useTrackingContext';
import { getAdTests } from '@buzzfeed/adlib/dist/module/services/ab-tests/standalone';
import { getUserGeoCountry } from '@buzzfeed/bf-utils';
import { isServer } from '@buzzfeed/react-components/lib/utils/isServer';

function ABeagle({ children, pageName }) {
  /**
   * Inside hook, will fetch once userId is a defined value, otherwise will return
   *   default experiments object
   */
  const userId = useUserId({ cluster: CLUSTER });
  const { pageInfo, trackExperimentActive } = useTrackingContext();
  const { path } = useContext(PageContext);
  const returnedExperiments = useRef(null);

  const edition = pageInfo?.page_edition || 'en-us';

  // Ads experiments require additional page context for eligibility checks
  const AD_TESTS = getAdTests({
    isBPage: false,
    isHomePage: pageName === 'home',
    isNewBPage: false,
    isFeedPage: true,
    isBFO: true,
    isBFN: false,
    localizationCountry: edition,
    userCountry: isServer() ? '' : getUserGeoCountry(),
    edition,
    isAdPost: () => false,
  });

  // These args need to be memoized, or we end up with an infinite loop in useAbeagleExperiments hook
  const memoizedArgs = useMemo(
    () => ({
      userId,
      data: { ...pageInfo },
    }),
    [userId]
  );
  const experiments = useABeagleExperiments({
    abeagleHost,
    experimentConfig: [...EXPERIMENTS, ...AD_TESTS],
    source: 'buzz_web',
    ...memoizedArgs,
  });

  // keep track of page path for which experiments were fetched
  if (
    !returnedExperiments.current ||
    returnedExperiments.current.loaded !== experiments.loaded
  ) {
    returnedExperiments.current = {
      ...experiments,
      path,
    };
  }
  // mark previous experiments as stale while experiments for the new page are being fetched
  returnedExperiments.current.stale =
    returnedExperiments.current.path !== path;
  const loaded = returnedExperiments.current.loaded;
  // quick memoize for eligible experiments
  const experimentKeys = experiments.eligible
    ? Object.keys(experiments.eligible).join('|')
    : '';
  // fire tracking when experiments or path has changed
  useEffect(() => {
    if (!loaded || !experimentKeys.length) {
      return;
    }
    const experiment_id = [];
    Object.keys(experiments.eligible).forEach(key => {
      const experiment = experiments.eligible[key];
      // Only send status if bucketed (has variant in 'value')
      if (experiment && experiment.value) {
        const { id, version, value, variant_id } = experiment;
        experiment_id.push([key, id, version, value, variant_id].join('|'));
      }
    });
    // Fire one event for ALL eligible experiments (only one event should fire)
    trackExperimentActive({ experiment_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, loaded, experimentKeys]);

    /**
   *
   * @type {Function}
   * @param {String} experimentName
   * @param {String} onValue
   */
    const getFeatureFlagValue = (experimentName, onValue = 'on') =>
    isOn(experiments, experimentName, onValue);

  const getExperimentValue = (experimentName, options) =>
    getExperimentVariant(experiments, experimentName, options);

  return (
    <ABeagleContext.Provider
      value={{
        experiments: returnedExperiments.current,
        getFeatureFlagValue,
        getExperimentValue,
      }}
    >
      {children}
    </ABeagleContext.Provider>
  );
}

ABeagle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ])
};

export default ABeagle;
