/* eslint-disable camelcase */
import { useEffect, useContext } from 'react';
import { TrackingContext } from '../../contexts';
import { CLUSTER } from '../../constants';

export function useNavTracking() {
  const {
    context_page_id,
    context_page_type,
    destination,
    page_edition
  } = useContext(TrackingContext);

  useEffect(() => {
    const navInfo = {
      context_page_id,
      context_page_type,
      destination,
      page_edition
    };

    window.clientEventTracking = {
      getPageContextLayer: () => {
        return () => navInfo;
      },
      env: CLUSTER,
    };
  }, [context_page_id, context_page_type, destination, page_edition]);

  return () => window.clientEventTracking;
}
