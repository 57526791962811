import { useEffect } from 'react';
import { CLUSTER as cluster } from '../../constants';
import { ComscoreTracker } from '@buzzfeed/third-party-analytics';

const trackComscorePageview = () => {
  new ComscoreTracker({ cluster });
}

export function useComscoreTracking() {
  useEffect(() => {
    trackComscorePageview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
