import { useMemo, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '@buzzfeed/react-components';
import adsConfig from '../../constants/ads-config';
import Ad from '@buzzfeed/adlib/dist/module/bindings/react/components/Ad';
import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import { isServer } from '@buzzfeed/react-components/lib/utils/isServer';
import * as adUnitUtils from '../../utils/ads/ad-unit';
import { PageContext, StickyContext } from '../../contexts';

function AdUnit({
  className = '',
  config,
  getBFPLayout = null,
  mode,
  onAdRender,
  onAdViewed,
  stickyWithinPlaceholder = false,
  type,
}) {
  const [adStatus, setAdStatus] = useState('loading');
  const stickyContext = useContext(StickyContext);
  const { pagePath } = useContext(PageContext);
  const defaultRenderLookahead =
    !isServer() && window.matchMedia && isMobile() ? 'x1.0' : 'x1.25';

  const classNameList = [
    stickyWithinPlaceholder && 'ad-sticky-within-placeholder',
    className,
  ].filter(Boolean).join(' ');

  const cfg = useMemo(() => {
    const clonedConfig = { ...(config || adsConfig[type]) };
    if (!('renderLookahead' in clonedConfig)) {
      clonedConfig.renderLookahead = defaultRenderLookahead;
    }
    if (/^awareness/.test(clonedConfig.adPos)) {
      clonedConfig.adPos = 'awareness';
    }
    return clonedConfig;
  }, [config, type, defaultRenderLookahead]);

  const handleAdRender = useCallback(
    ad => {
      if (ad.type) {
        setAdStatus(ad.type === 'empty' ? 'unfilled' : 'loaded');
      }
      if (typeof onAdRender === 'function') {
        onAdRender(ad);
      }
    },
    [cfg, onAdRender] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setAdStatus('loading');
  }, [cfg]);

  return (
    <Ad
      adUnitUtils={adUnitUtils}
      config={cfg}
      pageId={pagePath}
      mode={mode}
      onAdViewed={onAdViewed}
      onAdRender={handleAdRender}
      getBFPLayout={getBFPLayout}
      stickyManager={stickyContext}
      className={`${classNameList} Ad--${adStatus}`}
    />
  );
}

AdUnit.propTypes = {
  type: PropTypes.string,
  config: PropTypes.object,
  mode: PropTypes.oneOf(['active', 'preload']),
  onAdViewed: PropTypes.func,
  onAdRender: PropTypes.func,
  pageLanguage: PropTypes.string,
  className: PropTypes.string,
};

export default withErrorBoundary(AdUnit, {
  onError: (error) => console.error('Error caught in Ads', error),
});
