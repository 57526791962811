import { useEffect } from 'react';
import { useTrackingContext } from './useTrackingContext';
import cookies from '@buzzfeed/bf-utils/lib/cookies';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { urlQueryToObject } from '@buzzfeed/bf-utils/lib/query-string';

/**
 * Sets up and initializes addressability tracking.
 */
export function useAddressabilityTracking() {
  const { trackAddressability } = useTrackingContext();

  useEffect(() => {
    const hem = cookies.get('hem');
    const user = cookies.get('bf2-b_info');
    const queryObject = urlQueryToObject(window.location.search);

    let addressableSource = [];
    if (queryObject['email_hash'] || storage.sessionGet('newsletterAddressable')) {
      storage.sessionSet({ key: 'newsletterAddressable', value: 'true' });
      addressableSource.push('newsletter');
    }
    if (user) {
      addressableSource.push('auth');
    }

    trackAddressability({
      is_addressable: !!hem,
      addressable_source: addressableSource,
      addressable_partner: []
    });
  }, []);
}
