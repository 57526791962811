import { useEffect, useState } from 'react';
import { useTrackingContext } from './useTrackingContext';

export const useViewImpression = (
  {
    trackingData = {},
    condition = true, // optional impression tracking
  },
  /**
   * Allow the caller to specify dependencies, giving them more control over when the effect should
   * run. This is useful when the caller wants to track impressions based on a specific condition
   * that is not directly related to the intersecting state. Use case example, firing an
   * impression on a carousel slides when the slide index changes.
   */
  dependencies = []
) => {
  const { trackClientImpressionHandler } = useTrackingContext();
  const [ observable, setObservable ] = useState(null);

  useEffect(() => {
    let removeImpressionHandler = null;

    if (observable && condition) {
      removeImpressionHandler = trackClientImpressionHandler(observable, trackingData);
    }

    return () => {
      if (removeImpressionHandler) {
        removeImpressionHandler();
      }
    }
  }, [observable, ...dependencies]);

  return { setObservable };
};
