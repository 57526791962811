import { useEffect, useContext } from 'react';
import { getReferrer } from '@buzzfeed/bf-utils/lib/referrer';
import { useTrackingContext } from './useTrackingContext';
import { PageContext } from '../../contexts';
import { trackFacebookPageView } from '../analytics/facebook';
import { trackPermutivePageView } from '../analytics/permutive';

export function usePageviewTracking() {
  const { pageInfo, trackPageview } = useTrackingContext();
  const { path } = useContext(PageContext);
  const edition = pageInfo.page_edition;

  const pathParts = path?.split('/')?.filter((part) => part !== '');
  let section;
  let type;
  if (pageInfo?.context_page_id === 1) {   // home pages, regardless of edition
    section = 'homepage';
    type = 'homepage';
  } else {
    type = pathParts[0];
    if (pathParts.length > 1) {
      section = pathParts[1];
    }
  }

  useEffect(() => {
    // run hook once
    trackPageview({}); // with run with the pageInfo data in tracking context
    trackFacebookPageView({
      section,
      Edition: edition,
      SocialReferral: getReferrer(),
    });
    trackPermutivePageView({
      edition,
      section,
      type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ path ]);
}
