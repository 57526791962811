import {
  sizes,
  config as originalConfig,
} from '@buzzfeed/adlib/dist/module/bindings/react';

// TODO: Probably ad these to the ad-config inside adlib/react
const storyConfig = {};
for (let i = 1; i < 41; i++) {
  const pos = `story${i}`;
  storyConfig[pos] = {
    adType: 'post',
    adPos: pos,
    pos,
    wid: i,
    targeting: {
      pos: [pos],
      wid: i,
    },
    platform: 'autodetect',
    position: null,
    viewability: i < 3 ? 'high' : 'low',
    size: [
      sizes.FLUID,
      sizes.NATIVE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_LEADERBOARD,
    ],
    strategy: i === 1 ? 'batch' : 'single',
  };
}
storyConfig['story1'].loadgroup = 'top';

// TODO: Add to adlib config
const sidebarConfig = {};
const baseSidebarConfig = {
  adType: 'post',
  adPos: 'bigstory',
  platform: 'desktop',
  wid: 130,
  size: [
    sizes.NATIVE,
    sizes.FLUID,
    sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    sizes.PROGRAMMATIC_VERTICAL,
  ],
  strategy: 'single',
};
sidebarConfig['bigstory'] = {
  ...baseSidebarConfig,
  adPos: 'bigstory',
  strategy: 'batch',
  loadgroup: 'top',
};
for (let i = 0; i < 10; i++) {
  let sidebarPos = `promo${i + 1}`;
  sidebarConfig[sidebarPos] = {
    ...baseSidebarConfig,
    adPos: sidebarPos,
    wid: 230 + i,
    pos: sidebarPos,
  };
}
sidebarConfig['promo-infinite'] = {
  ...baseSidebarConfig,
  adPos: 'promo-infinite',
  wid: 2300,
};

// extend the original config for one import across all
export const adsConfig = Object.freeze({
  ...originalConfig,
  ...storyConfig,
  ...sidebarConfig,
});

export default adsConfig;
