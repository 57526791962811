import { useContext } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { SiteHeader } from '@buzzfeed/react-components';
import { StickyContext } from '../../contexts';
import { adsContextAsString } from '@buzzfeed/adlib/bindings/react/utils.js';
import { getPoe } from '@buzzfeed/adlib/dist/module/services/page-targeting';
import striptags from 'striptags';

// this cookie required to get the bf_header_ui to work...
// an imported module w/in the header service relies on this
const WINDOW_GLOBALS = `
  window.BZFD = window.BZFD || {};
  window.BZFD.Config = {
    bfwInfoCookie: 'bf2-b_info'
  };
`;

const getPoeData = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return getPoe({
    env: {
      isBPage: false, // the isBPage is always `false` on Tasty
    }
  });
};

export function HomepageSiteHeader({
  html = '',
  js = '',
  css,
  communityPage = false,
}) {
  const stickyRegistry = useContext(StickyContext);
  const router = useRouter();
  return (
    <>
      <Head>
        <script
          key="window-globals"
          dangerouslySetInnerHTML={{ __html: WINDOW_GLOBALS }}
        />
        <script
          key="ads-context"
          dangerouslySetInnerHTML={{
            __html: adsContextAsString({
              badges: [],
              cms_tags: [],
              dfp_keyword: striptags(router.query['dfp-keyword']) || null,
              id: 'home',
              section: [],
              zone3: '',
              isTasty: false,
              pagetype: 'A',
              poe: getPoeData(),
            }),
          }}
        />
      </Head>
      {communityPage ? (
        <SiteHeader html={html} js={js} css={css} />
      ) : (
        <SiteHeader
          html={html}
          js={js}
          css={css}
          stickyRegistry={stickyRegistry}
        />
      )}
    </>
  );
}

export default HomepageSiteHeader;
