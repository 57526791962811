import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';

const featureFlags = [
  /*
  // Example usage
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: () => true,
  },*/
  {
    name: 'TimeSpentRO_1',
    variations: ['on', 'control'],
    isEligible: () => true
  },
  {
    name: 'RT-1042-Admiral-script-on-BFDC',
    variations: ['control', 'on'],
    isEligible: () => true,
  }
];

export const serviceAbTests = [
  {
    name: 'ml-3115-home-feed-timespent-ranking',
    variations: ['control', 'home-feed-timespent-v2', 'randomized'],
    isEligible: (data) => {
      return data.context_page_id === 1 && data.page_edition === 'en-us';
    },
  },
];

export const abTests = [
  CONSENT_FEATURE_FLAG,
  ...featureFlags,
  ...serviceAbTests,
];

export default abTests;
