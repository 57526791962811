export const Fire = ({ className = '' }) => (
  <svg
    className={className}
    fill="#EB5369"
    height="19"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 18 19"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.57 1.47A.7.7 0 0 1 6.24.5c2.02.07 3.42.68 4.35 1.54.76.7 1.18 1.53 1.39 2.3.13-.24.21-.5.26-.82.05-.4.45-.74.92-.58a6.08 6.08 0 0 1 2.72 2.22 9.78 9.78 0 0 1 1.63 3.73c.57 2.77-.09 5.94-3.17 7.97a10.5 10.5 0 0 1-6.06 1.64 8.1 8.1 0 0 1-5.2-1.64 6.87 6.87 0 0 1-2.36-3.78A7.58 7.58 0 0 1 .88 8.9a4.96 4.96 0 0 1 2.37-2.93c.5-.25 1 .16 1 .62.01.4.04.64.1.8.03.07.07.13.16.18A4.28 4.28 0 0 0 6 5.45c.27-.97.24-2.25-.44-3.98Zm2.92 9.04c.07.7.06 1.26-.12 1.7-.22.54-.64.83-1.15 1.01a.72.72 0 0 1-.5 0c-.28-.1-.6-.3-.81-.64-.59.54-.87 1.65-.57 2.72.16.57.48 1.09.99 1.47.5.38 1.2.64 2.16.64 1.25 0 2.06-.3 2.72-.72a4 4 0 0 0 1.9-3.14c.04-1-.34-1.8-.86-2.14l-.03.04-.2.3c-.16.25-.39.5-.6.72-.32.3-.74.21-.95.11a1 1 0 0 1-.58-.66c-.15-.73-.68-1.34-1.4-1.41Z"
      fillRule="evenodd"
    />
  </svg>
);
