import React from 'react';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import { StickyContext } from '../../contexts';

/**
 * Context provider
 */
const StickyManager = function({ children }) {
  const notify = (_, { callback }) => callback({ shouldStick: true });
  return (
    <StickyContext.Provider value={{
      ...stickyRegistry,
      notify
    }}>{children}</StickyContext.Provider>
  );
};

export default StickyManager;
