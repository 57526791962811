import React, { useContext } from 'react';
import { addParams } from '@buzzfeed/bf-utils/lib/query-string';
import LinkWithTracking from '../LinkWithTracking';
import EmojiReactions from '../EmojiReactions';
import ImagePlaceholder from '../../static/img/image-placeholder.svg';
import { useViewImpression } from '../../hooks';
import { Fire } from '../../icons/Fire';
import { SpeechBuble } from '../../icons/SpeechBuble';
import { EyeActive } from '../../icons/EyeActive';
import { PageContext, TrackingContext } from '../../contexts';
import styles from './feedCard.module.scss';

export const FeedCard = ({ children, className, isTrackable = false, trackingData = {} }) => {
  const { setObservable } = useViewImpression({
    trackingData
  });
  const attachImpressionObserver = (el) => {
    if (!isTrackable) {
      return;
    }
    setObservable(el);
  }

  return (
    <article className={className ? className : ''} ref={obvEl => attachImpressionObserver(obvEl)}>
      {children}
    </article>
  );
};

FeedCard.Link = function FeedCard({
  children,
  url,
  className = '',
  isTrackable = true,
  trackingData = {},
  forceNewBrowserTab = false,
}) {
  if (!url) {
    return null;
  }
  if (isTrackable) {
    return (
      <LinkWithTracking
        href={url}
        className={className ? className : ''}
        commonTrackingData={trackingData}
        forceNewBrowserTab={forceNewBrowserTab}
      >
        {children}
      </LinkWithTracking>
    );
  } else {
    return (
      <a
        href={url}
        className={className ? className : ''}
        target={forceNewBrowserTab ? '_blank' : '_self'}
        rel={forceNewBrowserTab ? 'noreferrer' : undefined}
      >
        {children}
      </a>
    );
  }
}

FeedCard.Reactions = function FeedCard({
  className = '',
  contentId,
  contentType = 'buzz',
  data,
  isTrackable = false,
  trackingData = {}
}) {
  const contextTrackingData = useContext(TrackingContext);
  const { isSponsored = false } = useContext(PageContext);

  if (!data?.reactions) {
    return null;
  }

  return (
    <EmojiReactions
      className={className}
      contentId={contentId}
      contentType={contentType}
      data={data}
      isSponsored={isSponsored}
      isTrackable={isTrackable}
      trackingData={{...contextTrackingData, ...trackingData}}
    />
  );
}

FeedCard.InfoLabel = function FeedCard({
  className = '',
  label = '',
  createdAt = ''
}) {
  if (!createdAt && !label) {
    return null;
  }
  return (
    <header
      className={`${styles.info} ${className ? className : ''}`}
    >
      {label && (
        <span className={styles.tag}>
          <Fire class="xs-mr1"/> {label}
        </span>
      )}
      {createdAt && (
        <time dateTime={createdAt}>{createdAt}</time>
      )}
    </header>
  );
}

FeedCard.Stats = function FeedCard ({
  className = '',
  viewsCount = '',
  isTrackable = false,
  commentsClassName = '',
  commentsCount = '',
  forceCommentLink = false,
  commentLink = '',
  trackingData = {}
}) {
  if (!viewsCount?.length && !commentsCount?.length) {
    return null;
  }
  const CommentsStats = () => (
    <>
      <SpeechBuble />
      <span className="screen-reader-only"> The amount of the post comments: </span>
      {commentsCount}
    </>
  );
  const Comments = () => {
    const utlWithParams = addParams(commentLink, {open_comments: 'on'});
    if (!commentLink.length) {
      return <CommentsStats />;
    }
    if (!!commentLink.length && !isTrackable) {
      return (
        <a
          href={utlWithParams}
          className={commentsClassName ? commentsClassName : ''}
          target={forceCommentLink ? '_blank' : '_self'}
          rel={forceCommentLink ? 'noreferrer' : undefined}
        >
          <CommentsStats />
        </a>
      )
    }
    if (!!commentLink.length && isTrackable) {
      const commentsTrackingData = {
        ...trackingData,
        item_type: 'button',
      }
      return (
        <LinkWithTracking
          href={utlWithParams}
          className={commentsClassName ? commentsClassName : ''}
          forceNewBrowserTab={forceCommentLink}
          commonTrackingData={commentsTrackingData}
        >
          <CommentsStats />
        </LinkWithTracking>
      )
    }
  }
  return (
    <ul className={`${styles.stats} ${className ? className : ''}`}>
      {viewsCount && (
        <li>
          <EyeActive aria-hidden="true" />
          <div className="screen-reader-only"> The amount of the post views: </div>
          {viewsCount}
        </li>
      )}
      {commentsCount && (
        <li>
          <Comments />
        </li>
      )}
    </ul>
  );
};

FeedCard.Thumbnail = function FeedCard({ className = '', thumbnail = '', alt = '', lazyLoadThumbnail = false, ratio = '3/2', ratioTablet, ratioDesktop }) {
  const imageRatioTablet = ratioTablet ? ratioTablet : ratio;
  const imageRatioDesktop = ratioDesktop ? ratioDesktop : imageRatioTablet;
  return (
    <img
      className={`${styles.thumbnail} ${className ? className : ''}`}
      src={!!thumbnail.length ? thumbnail : ImagePlaceholder}
      alt={alt}
      loading={lazyLoadThumbnail ? 'lazy' : ''}
      style={{ '--thumbnailAspectRatio': ratio, '--thumbnailAspectRatioTablet': imageRatioTablet , '--thumbnailAspectRatioDesktop': imageRatioDesktop }}
    />
  );
}

FeedCard.IndexLabel = function FeedCard({ className = '', children }) {
  if (!children) {
    return null;
  }
  return (
    <span className={className ? className : undefined}>
      <span className="screen-reader-only"> Position in the list: </span>
      <span> {children} </span>
    </span>
  );
}

FeedCard.Section = function FeedCard ({children, className = ''}) {
  if (!children) {
    return null;
  }
  return (
    <div className={`${styles.section} ${className}`}>
      <span>
        {children}
      </span>
    </div>
  );
}

export default FeedCard;
