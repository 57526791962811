/*eslint-disable camelcase*/
import { useContext, useMemo } from 'react';
import EmojiReactions from '../EmojiReactions';
import styles from './feedSocial.module.scss';
import Script from 'next/script';
import { PageContext } from '../../contexts';
import { useViewImpression } from '../../hooks';

export const FeedSocial = ({ item = {}, isTrackable, trackingData }) => {
  const { content, id: contentObjectId, object_type: objectType } = item || {};
  const { trackingData: algorithmTrackingData } = content;
  const embed = content?.oembed_data?.html || '';

  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
    position_in_subunit: 0,
  };

  const script = useMemo(() => {
    // Get script url from the social embed (string)
    // https://regex101.com/r/3KEWVr/1
    const srcMatch = embed.match(/src=["'](.*(instagram|reddit|twitter|tiktok).*?(embed|s.js|widgets.js).*?)["']/);
    const srcAttribute = srcMatch ? srcMatch[1] : null;

    return srcAttribute
      ? <Script src={srcAttribute}></Script>
      : null;
  }, [content]);

  const sharedTrackingData = {
    ...algorithmTrackingData,
    ...subunitTrackingData,
    item_type: 'button',
    item_name: content?.oembed_data?.url || contentObjectId,
    target_content_type: 'url',
    ...trackingData,
  };

  const { isSponsored } = useContext(PageContext);
  const description = content?.description;

  // impression tracking
  const { setObservable } = useViewImpression({ trackingData: sharedTrackingData });

  return embed ? (
    <article className={styles.feedSocialContainer} ref={obvEl => setObservable(obvEl)}>
      <header className={styles.header}>
        <h2 className={styles.title}>{content?.title}</h2>
      </header>
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.embed}>
        <div dangerouslySetInnerHTML={{ __html: embed }} />
        {script}
      </div>
      <EmojiReactions
        className={styles.reactions}
        contentId={contentObjectId}
        contentType="content-object"
        data={content?.content_reactions}
        isSponsored={isSponsored}
        isTrackable={isTrackable}
        trackingData={sharedTrackingData}
      />
    </article>
  ) : null;
};

export default FeedSocial;
