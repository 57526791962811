import { useEffect } from 'react';
import { useTrackingContext } from './useTrackingContext';

/**
 * Sets up and initializes tracking of performance metrics.
 * @param {...object} layers - Optional, additional fields to include in the event
 */
export function usePerformanceTracking(...layers) {
  const { trackPerformance } = useTrackingContext();
  useEffect(() => {
    trackPerformance(...layers);
    // The empty dependency array is used below as we only want to send
    // load-performance metrics on the initial load.
    // This is because load timings after a dynamic route change are not as representative
    // given hydration (and other operations) will have already occurred
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
