export const TASTY = 'tasty';
export const SHOPPING = 'shopping';
export const TRENDING = 'trending';
export const MORE = 'more';
export const NEWS = 'news';
export const QUIZ = 'quiz';
export const SPLASH = 'splash';
export const VIDEO = 'video';
export const SPONSORED = 'sponsored';

export const MAX_AGE_24HR = 86400;
export const MAX_AGE_SHORT = 60; // 1 min

export const ACTIVE_TAB_EVENT = 'activeTabListener';
export const FEED_CONTENT_TRANSITION_END = 'feedContentTransitionEnd';
