export const EyeActive = ({ className = '' }) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4378 9.18761C12.8078 15.4331 5.18809 15.4514 2.55809 9.19762C2.52214 9.11213 2.52247 8.89639 2.55809 8.81165C5.18809 2.55783 12.808 2.56309 15.4378 8.80873C15.4788 8.9062 15.4793 9.08906 15.4378 9.18761ZM11.25 9.5625C11.25 10.8051 10.1178 11.8125 9.01202 11.8125C7.90621 11.8125 6.75 10.8051 6.75 9.5625C6.75 8.31986 7.90621 7.3125 9.01202 7.3125C10.1178 7.3125 11.25 8.31986 11.25 9.5625Z" fill="#F0FA81" stroke="none" strokeWidth="0.75" strokeLinejoin="round"/>
  </svg>
);
