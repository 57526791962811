import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import {
  sizes,
  programmaticSizes,
} from '@buzzfeed/adlib/dist/module/bindings/react';
import { CLUSTER, bfUrl } from '../../constants';
import { cookies } from '@buzzfeed/bf-utils';

export function getUserGeoCountry() {
  return cookies.get('bf-geo-country') || 'US';
}


/**
 *
 * @param {String} edition - the edition derived from the path, e.g. mx, au, etc
 * @param {Object} abeagle - a/b test + feature flag functions
 * @returns {Promise<*>}
 */
export async function getPageContext({ abeagle, pageName, tracking }) {
  // there's no edition routing on feed-ui yet, but should fix 
  // if this changes - CA 4/12/24
  const localization = {
    edition: 'en-us',
    language: 'en',
    country: 'us',
  };

  const userLocale = 'en_us';

  const env = {
    // constant values
    adSizes: sizes,
    programmaticSizes,
    destination: 'buzzfeed',
    isBFN: false,
    isE2ETest: false,
    isFeed: false,
    isFeedPage: true,
    isFeedpager: false,
    isHomePage: pageName !== 'topic',
    isNewsPost: false,
    isBFO: true,
    isBPage: false,
    isDev: CLUSTER === 'dev',
    isProd: CLUSTER === 'prod',
    pageFilter: null,
    pageFilters: {},
    pageMainFilter: null,
    type: CLUSTER,
    webRoot: bfUrl,
    allPageSections: [],
    author: null,
    cmsTags: [],
    hasQuiz: false,
    isAsIs: false,
    isPharmaceutical: false,
    isWidePost: false,
    pageCategory: '',
    pageId: 'home', // for cet performance tracking
    pageName,
    isAdPost() {
      return false;
    },
    // variable values
    localization,
    locale: userLocale.locale,
    localizationCountry: userLocale.country,
    userCountry: getUserGeoCountry(), // for geo-based zone1 ad call changes
  };

  return {
    env,
    abeagle,
    localization,
    // since adlib is loaded as a separate chunk
    // and `stickyRegistry`'s global state needs to be shared between the main chunk and adlib,
    // we're passing it as a dependency (instead of just importing in adlib)
    stickyRegistry,
    tracking,
  };
}
