const NAMESPACE = 'user-entries';
const LIMIT = 100;

/**
 * Creates a local user entry handler containing methods for getting and setting user entries.
 * @param {String} options.namespace
 * @param {Number} options.limit
 * @returns
 */
export const localUserEntries = ({ namespace = NAMESPACE, limit = LIMIT } = {}) => ({
  /**
   * Returns all user entries with the users most recent entry at the front of the array.
   * @returns {Array}
   */
  getAll() {
    try {
      return JSON.parse(window.localStorage.getItem(namespace)) || [];
    } catch {
      return [];
    }
  },

  /**
   * Returns a users entry for a specified key with the users most recent entry at the front of the
   * array.
   * @param {String} options.entry
   * @returns {String}
   */
  get(entry = '') {
    if (!entry) {
      throw new Error('An `entry` value is required.');
    }

    const userEntries = this.getAll();
    return userEntries.find(item => item.startsWith(entry)) || '';
  },

  /**
   * Saves user entry in localStorage. Recent entries will be placed at the front of the array or
   * moved to the front if it already exists.
   * @param {String} options.entry
   */
  set(entry = '') {
    if (!entry) {
      throw new Error('An `entry` value is required.');
    }

    let userEntries = this.getAll();

    if (userEntries.includes(entry)) {
      userEntries.splice(userEntries.indexOf(entry), 1);
    } else {
      userEntries.unshift(entry);
    }

    if (limit && userEntries.length > limit) {
      userEntries = userEntries.slice(0, limit);
    }

    window.localStorage.setItem(namespace, JSON.stringify(userEntries));
  },
});
