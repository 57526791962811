import { sizes, programmaticSizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import sizeUtils from '@buzzfeed/adlib/dist/module/services/size/standalone';
import {
  components as adlibComponents,
  templates as adlibTemplates,
} from './adlib';

export function isWideInline(adPos) {
  return /^promo\d*-wide/.test(adPos);
}

// using `promo` pos for now to match old HP
// before full/total rollout and/or
// an update to ad calls in general across this service + feedpager
export function isSidebarAd(adPos) {
  return /^promo/.test(adPos) || /^bigstory/.test(adPos);
}

// eslint-disable-next-line consistent-return
export const getTemplate = adConfig => {
  const { adPos } = adConfig;
  if (adConfig.useTemplate || adConfig.useComponent) {
    return adlibTemplates[adConfig.useTemplate || adConfig.useComponent];
  }
  if (/^awareness/.test(adPos)) {
    return adlibTemplates.AdAwareness;
  } else if (/^story/.test(adPos)) {
    return adlibTemplates.AdFeedStory;
  } else if (isSidebarAd(adPos)) {
    return adlibTemplates.AdBigstory;
  } else if (isWideInline(adPos) || /^promo/.test(adPos)) {
    return adlibTemplates.AdEx;
  } else if (/tb$/.test(adPos)) {
    return adlibTemplates.AdToolbar;
  }
};

export const getComponent = adConfig => {
  const { adPos } = adConfig;
  if (adConfig.useComponent) {
    return adlibComponents[adConfig.useComponent];
  }
  if (/^awareness/.test(adPos)) {
    return adlibComponents.AdAwareness;
  } else if (/^story/.test(adPos)) {
    return adlibComponents.AdFeedStory;
  } else if (isSidebarAd(adPos)) {
    return adlibComponents.AdBigstory;
  } else if (/tb$/.test(adPos)) {
    return adlibComponents.AdToolbar;
  }
  return adlibComponents.AdEx;
};

export const buildPosBound = (posLabel, posNum, max, infinitePos = null) => {
  let pos = `${posLabel}${posNum}`;
  if (posNum > max) {
    pos = infinitePos !== null ? infinitePos : `${posLabel}${max}`;
  }
  return pos;
};

// TODOL: for semi-sticky later
export const getMaxSizes = adConfig => {
  const psizes = sizeUtils.getProgrammatic(programmaticSizes, adConfig.size);
  const maxHeight = Math.max(0, ...psizes.map(([, h]) => h));
  const maxWidth = Math.max(0, ...psizes.map(([w]) => w));
  return [maxWidth, maxHeight];
};

export const addBillboards = (adConfig) => {
  return sizeUtils.add(
    adConfig.size,
    sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
    sizes.PROGRAMMATIC_BILLBOARD
  );
};

export const getWireframeOptions = adConfig => {
  const maxSizes = getMaxSizes(adConfig);
  return {
    programmaticWireframes: true,
    wireframeWidth: maxSizes[0],
    wireframeHeight: maxSizes[1],
    wireframeClasses: defaultClasses =>
      `${defaultClasses} ad-wireframe-wrapper--inline`,
  };
};
