export const EmojiReactionAdd = ({ className }) => (
  <svg
    aria-label="Add emoji reaction"
    className={className}
    height="20"
    role="img"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
    fill="#757575"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 22 20"
  >
    <path d="M5.43 7.3a1.37 1.37 0 1 1 1.94 1.93A1.37 1.37 0 0 1 5.43 7.3Zm5.49 0a1.37 1.37 0 1 1 1.94 1.93 1.37 1.37 0 0 1-1.94-1.94Z"/>
    <path fillRule="evenodd" d="M9.15 13.3c1.2 0 2.2-.84 2.45-1.96a.91.91 0 1 1 1.79.41 4.35 4.35 0 0 1-8.48 0 .91.91 0 1 1 1.79-.4 2.52 2.52 0 0 0 2.45 1.94Z" clipRule="evenodd"/>
    <path d="M2.68 4.31a9.15 9.15 0 0 1 10.1-1.93c-.13.24-.2.52-.2.81l.01 1.13a7.32 7.32 0 1 0 3.71 4.9c.15.05.3.07.47.07h1.4A9.14 9.14 0 1 1 2.69 4.31Z"/>
    <path d="M16.77 7.58V5.1H14.3V3.2h2.48V.71h1.9v2.47h2.49v1.9h-2.48v2.49h-1.9Z"/>
  </svg>
);
