import { each, mapValues, findKey } from 'lodash';
import { useState, useEffect } from 'react';

export const useMediaQuery = () => {
  const [breakpoint, setBreakpoint] = useState('');

  useEffect(() => {
    const queries = {
      xs: '(max-width:40rem)',
      sm: '(min-width:40rem) and (max-width:52rem)',
      md: '(min-width:52rem) and (max-width:64rem)',
      lg: '(min-width:64rem)',
    };

    let mounted = true;

    const listeners = mapValues(queries, query => window.matchMedia(query));

    const onChange = () => {
      if (!mounted) return;
      const matchedKey = findKey(listeners, l => l.matches);
      setBreakpoint(matchedKey);
    };

    each(listeners, l => l.addEventListener('change', onChange));

    onChange();

    return () => {
      mounted = false;
      each(listeners, l => l.removeEventListener('change', onChange));
    };
  }, []);

  const isMobile = breakpoint === 'xs' || breakpoint === 'sm';
  const isTablet = breakpoint === 'sm';
  const isDesktop = breakpoint === 'md' || breakpoint === 'lg';
  const isLargeScreen = breakpoint === 'lg';

  return { breakpoint, isMobile, isTablet, isDesktop, isLargeScreen };
};
