// would be taken from here - https://github.com/buzzfeed/mono/blob/master/bf_bpage_ui/src/hooks/analytics/useLegacyTracking/index.js
// can we remove? for new hp?
// this is all temporary for ads to work for now
import { createClientEvent } from '@buzzfeed/client-event-tracking';
import { CLUSTER } from '../../constants';
import { useTrackingContext } from './useTrackingContext';

const sendLegacyClientEvent = createClientEvent({
  env: CLUSTER,
  source: 'buzz_web',
});

/**
 * Primarily for ads prebid bid tracking patch
 * TODO: remove this when ads tracking is upgraded
 * @param {Object} data - bid data and other ads data that is sent through
 */
function trackLegacyBid(data) {
  sendLegacyClientEvent(
    { type: 'instrumentation' },
    {
      target: 'ads',
      value: data.eventName,
      tags: data.tags || {},
    }
  );
}

/**
 *
 * @param {Object} tracker - the tracking object defined in useLegacyTracking/index.js
 */

function addBfpEvents(tracker, trackingActions) {

  const {
    trackClientInternalLinkHandler,
    trackClientExternalLinkHandler,
    trackClientContentAction,
    trackClientImpressionHandler,
    trackContentAction,
    trackInternalLink,
    trackExternalLink,
  } = trackingActions;

  const bfpEvents = {
    trackBfpClientContentAction({ data }) {
      trackContentAction(data);
    },

    trackBfpClientLinkClick({ data }) {
      if (data.target_content_url) {
        trackExternalLink(data);
      } else {
        trackInternalLink(data);
      }
    },

    bindBfpClientContentAction({ element, data }) {
      if (!element || !data) {
        return;
      }

      trackClientContentAction(element, data);
    },

    bindBfpProductTracking({ element, data, isWithImpression = true }) {
      if (!element || !data) {
        return;
      }

      if (isWithImpression) {
        trackClientImpressionHandler(element, data);
      }

      if (data.target_content_url) {
        trackClientExternalLinkHandler(element, data);
      } else {
        trackClientInternalLinkHandler(element, data);
      }
    },

  };

  // add functions to root tracker, to be called as tracker.functionName
  Object.keys(bfpEvents).forEach(k => (tracker[k] = bfpEvents[k]));
}

/**
 * Add Legacy BFA events for ADLIB until it's upgraded.
 */
function addAdEvents(tracker) {
  const prebidEvents = {
    trackPrebidEvents(data) {
      trackLegacyBid(data);
    },
  };
  Object.keys(prebidEvents).forEach(k => (tracker[k] = prebidEvents[k]));
}

export const useLegacyTracking = () => {
  const trackingActions = useTrackingContext();
  const tracking = {};

  addBfpEvents(tracking, trackingActions);
  addAdEvents(tracking);
  return tracking;
};

export default useLegacyTracking;
