import { useEffect } from 'react';
import { useTrackingContext } from './useTrackingContext';

// To be used once we do not want to use ABeagle for FF.
export function useTimeSpentTracking(tracking) {
  const { trackTimeSpent } = useTrackingContext();

  useEffect(() => {
    return trackTimeSpent(tracking);
    // this should have to run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
