import React from 'react';
import { gtm_id } from '../../constants';

function GoogleTagManager() {
  return (
    <noscript>
      <iframe
        title="google-tag-manager"
        src={`https://www.googletagmanager.com/ns.html?id=${gtm_id}`}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  );
}

export default GoogleTagManager;
